import { useCallback, useEffect, useMemo, useState } from 'react';
import { Cancel } from '@mui/icons-material';
import { Box, Checkbox, Chip, FormControlLabel, Grid, Paper, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { range } from 'd3';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { useLocalStorage } from 'usehooks-ts';
import { useSafetyPredictionServiceGetBerths } from '@/api/ui/queries';
import { BerthSafetyResponse, BerthSafetyWarningSummary, SafetyPredictionTrigger, SafetyPredictionType } from '@/api/ui/requests';
import { ReactComponent as SimulationView } from '@/assets/svg/simulation.svg';
import Legend from '@/components/charts/Legend/Legend';
import Select from '@/components/common/Select/Select';
import DailyBerthWarningsDialog from '@/features/SafetyPrediction/History/Wizard/DailyBerthWarningsDialog';
import HistoricWarningCalendar from '@/features/SafetyPrediction/History/Wizard/HistoricWarningCalendar';
import { safetyPredictionTypeColor } from '@/features/color-utils';
import useLicence from '@/hooks/useLicence';
import useOrganisation from '@/hooks/useOrganisation';
import usePageTitle from '@/hooks/usePageTitle';
import useSearchParamState from '@/hooks/useSearchParamState';
import { DATE_FORMAT, LicenseFeatures, SafetyPredictionTriggerStrings, StateProps } from '@/types';

const mooringLineLegendItems = (theme: Theme) => [
  { name: '≤ WLL', color: safetyPredictionTypeColor(theme)?.main },
  { name: '> 50% MBL', color: safetyPredictionTypeColor(theme, SafetyPredictionType.ABOVE_LOWER_LIMIT)?.main },
  { name: '> 70% MBL', color: safetyPredictionTypeColor(theme, SafetyPredictionType.ABOVE_UPPER_LIMIT, true)?.main },
  { name: '> MBL', color: safetyPredictionTypeColor(theme, SafetyPredictionType.ABOVE_UPPER_LIMIT, false)?.main },
];

const shrinkPaddingInputSx = { '.MuiOutlinedInput-input': { padding: 0.8 } };

const SelectPredictionTrigger = ({ value: selectedTrigger, setValue: setSelectedTrigger }: StateProps<SafetyPredictionTrigger>) => (
  <Select<SafetyPredictionTrigger, SafetyPredictionTrigger | null>
    label="Condition"
    options={Object.values(SafetyPredictionTrigger).filter((trigger) => trigger != SafetyPredictionTrigger.NONE)}
    value={selectedTrigger}
    displayFunction={(trigger) => SafetyPredictionTriggerStrings[trigger].name}
    onChange={(trigger) => setSelectedTrigger(() => trigger)}
    fullWidth={true}
    size={'small'}
    formControlSx={shrinkPaddingInputSx}
  />
);

const berthSelectOptions = ['All', 'Berths'];
const SelectBerths = ({ value, setValue }: StateProps<string[]>) => {
  const [selectedBerthIds, setSelectedBerthIds] = useState<string[]>(() => value);
  const [berthSelectOption, setBerthSelectOption] = useLocalStorage<string>(
    'historicOverview-berthSelectMode',
    selectedBerthIds.length == 0 ? berthSelectOptions[0] : berthSelectOptions[1]
  );
  const [shouldApplyBerthChanges, setShouldApplyBerthChanges] = useState<boolean>(() => false);
  const { selectedOrganisationId } = useOrganisation();

  const { data: berthOptions } = useSafetyPredictionServiceGetBerths(
    {
      xSelectedOrganisationId: selectedOrganisationId,
      startTime: moment().subtract(1, 'day').toISOString(),
      numberOfDays: 1,
    },
    [selectedOrganisationId],
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    if (shouldApplyBerthChanges) {
      setValue(selectedBerthIds);
      setShouldApplyBerthChanges(false);
    }
  }, [shouldApplyBerthChanges, setValue, selectedBerthIds]);

  const handleBerthsChange = useCallback(
    (val: typeof selectedBerthIds | string) => {
      const berthIds = typeof val === 'string' ? val.split(',') : val;
      setSelectedBerthIds(berthIds);
    },
    [setSelectedBerthIds]
  );

  const applySelectedBerths = useCallback(
    (selectedBerths: string[]) => {
      setShouldApplyBerthChanges(true);
      handleBerthsChange(selectedBerths);
    },
    [handleBerthsChange, setShouldApplyBerthChanges]
  );

  const handleBerthOptionChange = useCallback(
    (selected: string) => {
      setBerthSelectOption(selected);
      if (selected == berthSelectOptions[0] /*All*/) {
        applySelectedBerths([]);
      }
    },
    [setBerthSelectOption, applySelectedBerths]
  );

  const renderSelectedBerths = useCallback(
    (selectedBerthIds: string[]) => {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selectedBerthIds.map((item) => (
            <Chip
              sx={{ height: 'fit-content' }}
              key={item}
              label={item}
              clickable={true}
              color={'secondary'}
              deleteIcon={<Cancel sx={{ height: 18 }} onMouseDown={(event) => event.stopPropagation()} />}
              onDelete={() => applySelectedBerths(selectedBerthIds.filter((id) => id != item))}
            />
          ))}
        </Box>
      );
    },
    [handleBerthsChange]
  );

  if (!berthOptions?.length) return <></>;
  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <Select
          label="Search for"
          options={berthSelectOptions}
          onChange={handleBerthOptionChange}
          value={berthSelectOption}
          fullWidth={true}
          size={'small'}
          formControlSx={{ ...shrinkPaddingInputSx }}
        />
      </Grid>
      <Grid item xs={8}>
        {berthSelectOption == berthSelectOptions[1] /*Berths*/ && (
          <Select<BerthSafetyResponse, string | null>
            label="Berth"
            options={berthOptions.sort((a, b) => a.berthId.localeCompare(b.berthId))}
            value={selectedBerthIds}
            valueFunction={(berth) => berth.berthId}
            displayFunction={(berth) => `${berth.berthId} - ${berth.name}`}
            onChange={handleBerthsChange}
            fullWidth={true}
            size={'small'}
            multiple={true}
            onClose={applySelectedBerths}
            formControlSx={{ ...shrinkPaddingInputSx }}
            menuProps={{
              sx: { '& .MuiPaper-root': { maxHeight: '70vh' } },
            }}
            renderValue={renderSelectedBerths}
          />
        )}
      </Grid>
    </Grid>
  );
};

const SelectCalendarYear = ({ value, setValue }: StateProps<number>) => {
  const minYear = 2022;
  const maxYear = moment().year();

  return (
    <Select label={'Year'} size={'small'} multiple={false} value={value ?? maxYear} onChange={setValue} options={range(maxYear, minYear - 1, -1)} />
  );
};

const CheckOnlyDetectedVessels = ({ value, setValue }: StateProps<boolean>) => (
  <FormControlLabel
    control={<Checkbox size={'small'} sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} checked={value} onChange={(_, checked) => setValue(checked)} />}
    componentsProps={{ typography: { variant: 'highlightSemiBoldMedium' } }}
    label="Show only situations with AIS detected vessels"
  />
);

function HistoricOverview() {
  usePageTitle('Historical simulation', <SimulationView width={32} key={'Historical-simulation'} />);
  const theme = useTheme();
  const { hasFeature } = useLicence();

  const [selectedBerthParams, setSelectedBerthParams] = useSearchParamState<string[]>('berthIds', []);
  const [selectedDetectVesselOnlyParam, setSelectedDetectVesselOnlyParam] = useSearchParamState<boolean>('onlyDetectedVessels', false);
  const [dayParam, setDayParam] = useSearchParamState<string>('day', null);
  const [yearParam, setYearParam] = useSearchParamState<number>('year', moment().year());

  const [selectedTrigger, setSelectedTrigger] = useState<SafetyPredictionTrigger>(SafetyPredictionTrigger.LINE_FORCE);

  const selectedDetectVesselOnly = useMemo(() => {
    return hasFeature(LicenseFeatures.AIS_DETECTION) ? selectedDetectVesselOnlyParam : false;
  }, [selectedDetectVesselOnlyParam, hasFeature]);

  const warningFilter = useCallback(
    (warning: BerthSafetyWarningSummary) => {
      //all berths or selected berth
      const filteredBerths = selectedBerthParams.length == 0 || selectedBerthParams.includes(warning.berthId);
      const filteredCondition = warning.trigger == selectedTrigger;
      //all vessels or only detected vessels
      const filteredVessels = !selectedDetectVesselOnly || warning.isOperationalVessel;
      return filteredBerths && filteredCondition && filteredVessels;
    },
    [selectedBerthParams, selectedTrigger, selectedDetectVesselOnly]
  );

  const handleDaySelected = (day: Moment) => {
    setDayParam(day.format(DATE_FORMAT));
  };

  const handleCloseDialog = () => {
    setDayParam(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box
        bgcolor={theme.palette.grey[200]}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        mt={theme.spacing(-7)}
        sx={{ minHeight: `calc(100vh - ${theme.spacing(7)} )` }}
      >
        <Paper color={theme.palette.background.paper} sx={{ maxWidth: 1400, maxHeight: 760, overflowY: 'scroll', mt: 7 }} elevation={3}>
          <Grid container mt={3} px={3} display={'flex'} justifyContent={'space-between'}>
            <Grid item xs={7} px={2} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
              <Box width={'70%'}>
                <Typography variant={'h5'}>Calendar</Typography>
                <Typography variant={'bodyRegularMedium'}>Look back in time to find situations in which safety levels were exceeded.</Typography>
              </Box>
              <Legend items={mooringLineLegendItems(theme)} size={'small'} alignment={'left'} />
            </Grid>
            <Grid item xs={5}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SelectPredictionTrigger value={selectedTrigger} setValue={setSelectedTrigger} />
                </Grid>
                <Grid item xs={12}>
                  <SelectBerths value={selectedBerthParams} setValue={setSelectedBerthParams} />
                </Grid>
                <Grid item xs={3}>
                  <SelectCalendarYear value={yearParam} setValue={setYearParam} />
                </Grid>
                <Grid item xs={9}>
                  {hasFeature(LicenseFeatures.AIS_DETECTION) && (
                    <CheckOnlyDetectedVessels value={selectedDetectVesselOnly} setValue={setSelectedDetectVesselOnlyParam} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={6}>
            <HistoricWarningCalendar selectedYear={yearParam} warningFilter={warningFilter} onDateSelected={handleDaySelected} />
          </Grid>
        </Paper>
      </Box>
      {dayParam && (
        <DailyBerthWarningsDialog
          day={moment(dayParam)}
          selectedBerthIds={selectedBerthParams}
          withAisVesselsOnly={selectedDetectVesselOnly}
          onClose={handleCloseDialog}
        />
      )}
    </LocalizationProvider>
  );
}

export default HistoricOverview;
